import React, { useEffect, useState } from "react";
import {
	Box,
	BoxProps,
	Button,
	Link,
	Modal,
	Toolbar,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

import { FormattedPhrase } from "../../context/FormattedPhrase";

import components from "../../theme/components";

import "./Footer.scss";

type FooterProps = {
	width: number;
};

function Item(props: BoxProps) {
	const { sx, ...other } = props;
	const theme = useTheme();

	return (
		<Box
			sx={{
				bgcolor: (theme) =>
					theme.palette.mode === "dark" ? "#101010" : "#fff",
				color: (theme) =>
					theme.palette.mode === "dark"
						? "grey.300"
						: theme.palette.text.primary,
				p: 1,
				borderRadius: 2,
				fontSize: "0.875rem",
				fontWeight: 500,
				...sx,
				"& hr": {
					mx: 0.5,
				},
			}}
			{...other}
		/>
	);
}

const Footer: React.FC<FooterProps> = ({ width }) => {
	const [contactModalOpen, setContactModalOpen] = useState(false);

	return (
		<footer id="footer" className="fixed-bottom">
			<Box
				sx={{
					display: "grid",
					gridAutoColumns: "1fr",
					gap: 1,
				}}
			>
				<Modal
					open={contactModalOpen}
					onClose={() => setContactModalOpen(false)}
				>
					<Box sx={components.MuiModal}>
						<>
							<Typography variant="h3" my={2}>
								<FormattedPhrase id="dialog.contactUs.title" />
							</Typography>
							<Typography variant="subtitle1">
								<FormattedPhrase id="dialog.contactUs.text" isHtml={true} />
							</Typography>
						</>

						<Toolbar sx={{ justifyContent: "flex-end" }}>
							<Button onClick={() => setContactModalOpen(false)}><FormattedPhrase id="dialog.ok"/></Button>
						</Toolbar>
					</Box>
				</Modal>
				<Item sx={{ gridRow: "1", gridColumn: "span 1", fontWeight: 400, fontSize: "0.813rem" }}>
					<FormattedPhrase id="application.copyright" />
				</Item>
				<Item
					sx={{
						gridRow: "1",
						gridColumn: "span 1",
						display: "flex",
						alignContent: "center",
						alignItems: "center",
						flex: 1,
						fontSize: "0.813rem"
					}}
					className="right"
				>
					<Link
						href="https://www.ccl.org/about-us/"
						target="_blank"
						data-testid="link-about"
						rel="noreferrer"
						className="footer-link"
					>
						<FormattedPhrase id="appMenu.about" />
					</Link>
					<Divider
						sx={{ padding: 1 }}
						variant="middle"
						orientation="vertical"
						flexItem
					/>
					<Link
						href="https://www.ccl.org/policies/privacy-policy/"
						data-testid="link-privacy"
						target="_blank"
						rel="noreferrer"
						className="footer-link"
					>
						<FormattedPhrase id="appMenu.privacyPolicy" />
					</Link>
					<Divider
						sx={{ padding: 1 }}
						variant="middle"
						orientation="vertical"
						flexItem
					/>
					<Link
						className="footer-link"
						onClick={() => setContactModalOpen(true)}
					>
						<FormattedPhrase id="appMenu.contactUs" />
					</Link>
					<Divider
						sx={{ padding: 1 }}
						variant="middle"
						orientation="vertical"
						flexItem
					/>
					<Link
						href="https://support.ccl.org/home/"
						data-testid="link-help"
						target="_blank"
						rel="noreferrer"
						className="footer-link"
					>
						<FormattedPhrase id="appMenu.help" />
					</Link>
				</Item>
			</Box>
		</footer>
	);
};

export default Footer;
