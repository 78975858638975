import { useSelector } from "react-redux";
import { Fragment, useEffect } from "react";
import { Box, Grid } from "@mui/material";

import { Loading } from "../components";

import { RootState } from "../store";

import { Layout } from "../components/chrome/Layout";

import { AssessmentsForOthers } from "./home/AssessmentsForOthers";
import { AssessmentsForMe } from "./home/AssessmentsForMe";

import "./HomePage.scss";
import SkeletonLoader from "../components/shared/skeletonLoader";

export const HomePage: React.FC = () => {
	const { loading } = useSelector((state: RootState) => state.user);

	const { returnUrl, selectedSection } = useSelector(
		(state: RootState) => state.navigation
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (returnUrl !== "") {
			localStorage.setItem("redirectUser","");
			window.location.href = returnUrl;
		}
		else {
			localStorage.setItem("redirectUser","");
		}
	}, [returnUrl]);

	return (
		<Layout>
			<Box>
				{loading && 
				<Grid container columnSpacing={8} rowSpacing={5}>
					<Grid item xs={4} rowSpacing={1} mb={5}>
						<SkeletonLoader cardType="aboutMe" />
					</Grid>
					<Grid item xs={8} rowSpacing={1} mb={5}>
						<SkeletonLoader cardType="survey" />
						<SkeletonLoader cardType="survey" />
					</Grid>
				</Grid>}
				{returnUrl !== "" ? (
					<Fragment />
				) : (
					<Fragment>
						{selectedSection === "Own" && <AssessmentsForMe />}
						{selectedSection === "Others" && <AssessmentsForOthers />}
					</Fragment>
				)}
			</Box>
		</Layout>
	);
};
