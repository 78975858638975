import React, { useEffect, useState } from "react";
import VideoJS from "./VideoJS";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useEnv } from "../context/env.context";

interface VideoProps {
    source: string,
    title: string,
    poster?: string,
}

export const VideoPlayerWithAnalytics: React.FC<VideoProps> = ({
	source, title, poster
}) => {
    const { domain } = useEnv();
    const playerRef = React.useRef(null);

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // player.on('waiting', () => {
        //   console.log('player is waiting');
        // });

        player.on('play', () => {
            if (domain === "auth.ccl.org") {
                logEvent(getAnalytics(), "Video Played", {"Title": title})
            }
        }); 
    
        // player.on('dispose', () => {
        //   videojs.log('player will dispose');
        // });
    };

    return (
        <VideoJS 
            poster={poster ?? "https://public-assets.ccl.org/images/icons/ccl/android-chrome-512x512.png"} 
            source={source}
            onReady={handlePlayerReady}
        />
    );
}