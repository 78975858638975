import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

import {
	fetchProgramStatuses,
	fetchSurveyUsage,
	setLoading,
	setProgramStatuses,
} from "../../store/User/UserSlice";

import { getAllSurveyStatus, getSurveyUsage } from "../../services/apiService";

import { InstrumentStatus, ProgramStatus } from "../../@types/surveys.d";
import { RootState } from "../../store";

import { DisplayAboutMeStatus } from "../home/DisplayAboutMeStatus";
import { DisplayProgramInstrumentStatus } from "../home/DisplayProgramInstrumentStatus";

import { DisplayAdvertisement } from "../../components/ads/DisplayAdvertisement";
import { ResetSurveyStatus } from "./ResetSurveyStatus";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import SkeletonLoader from "../../components/shared/skeletonLoader";

export const AssessmentsForMe: React.FC = () => {
	const dispatch = useDispatch();

	const { user } = useAuth0();

	const { programStatuses, surveyUsageData, loading } = useSelector((state: RootState) => state.user);
	const { returnUrl } = useSelector((state: RootState) => state.navigation);

	const [aboutMeStatus, setAboutMeStatus] = useState<InstrumentStatus | null>(
		null
	);
	const [onlyAboutMe, setOnlyAboutMe] = useState<boolean>();
	const [programs, setPrograms] = useState<Array<ProgramStatus> | null>(null);

	useEffect(() => {
		if (returnUrl === "") {
			localStorage.setItem("redirectUser", "");
			localStorage.setItem("redirectOnLogOut", "");
			localStorage.setItem("returnToAp1", "");
		}
		if (programStatuses && programStatuses.length > 0) {
			let statuses: Array<ProgramStatus> = [];
			programStatuses.forEach((element) => {
				let newInstrumentStatuses: Array<InstrumentStatus> = [];
				element.instrumentStatuses.forEach((a) => {
					if (surveyUsageData) {
						surveyUsageData.map((x) => {
						if (a.instrumentId === x.instrumentKey) {
							newInstrumentStatuses.push({
								...a,
								isSurveyAvailable: x.active,
								surveyPlatform: x.surveyPlatform
							});
						}
						})
					}
					if (a.documentInfo?.documentId) {
						newInstrumentStatuses.push(a);
					}
					if (a.assetInfo?.assetInfoId && !a.isAssessment) {
						newInstrumentStatuses.push(a);
					}
				});
				let aboutMe = element.instrumentStatuses.find(
					(s) => s.instrumentId === 2946
				);
				if (aboutMe) {
					setAboutMeStatus(aboutMe);
				}
				let others = element.instrumentStatuses.find(
					(s) => s.instrumentId !== 2946
				);
				if (others) {
					setOnlyAboutMe(false);
				} else {
					setOnlyAboutMe(true);
				}
				statuses.push({
					...element,
					instrumentStatuses: newInstrumentStatuses
				})
			});
			setPrograms(statuses);
		}
	}, [programStatuses, surveyUsageData]);

	const asyncLoadingTasks = async () => {
		if (user) {
			dispatch(setLoading(true));
			try {
				let acctId = user["http://schemas.ccl.org/accounts/claims/account/id"];
				dispatch(fetchProgramStatuses(acctId));
				dispatch(fetchSurveyUsage());
			} finally {
				dispatch(setLoading(false));
			}
		}
	};

	useEffect(() => {
		localStorage.setItem("redirectUser", "");
		asyncLoadingTasks();
		window.scrollTo(0, 0);
	}, [user]);

	return (
		<Grid container columnSpacing={8} rowSpacing={5}>
			{!loading &&
				<>
				{
					aboutMeStatus ? (
						<Grid item xs={4}>
							<DisplayAboutMeStatus instrumentStatus={aboutMeStatus} />
							<DisplayAdvertisement />
						</Grid>
					)
					:
					<Grid item xs={4} rowSpacing={1} mb={5}>
						<DisplayAdvertisement />
					</Grid>
				}
				{onlyAboutMe ? (
					<Grid item xs={4}>
						<Typography variant="h5" mt={20}>
							<FormattedPhrase id="noWorkLanding.text" isHtml={true} />
						</Typography>
						<Typography variant="h5" mt={10}>
							<FormattedPhrase id="globalException.contactUs" isHtml={true} />
						</Typography>
					</Grid>
				) : programs && programs?.length > 0 ? (
					<Grid item xs={8} rowSpacing={5}>
						<DisplayProgramInstrumentStatus programStatuses={programs} />
					</Grid>
				) : (
					<Grid item xs={8} rowSpacing={1} mb={5}>
						<SkeletonLoader cardType="survey" />
						<SkeletonLoader cardType="survey" />
					</Grid>
				)}
				</>
			}
			<ResetSurveyStatus />
		</Grid>
	);
};
