import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	Button,
	Card,
	CardContent,
	CircularProgress,
	Grid,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from '@mui/icons-material/Info';
import NotificationsIcon from '@mui/icons-material/Notifications';

import {
	EmailRequest,
	InstrumentRaterResponse,
	Rater,
	RaterStatus,
	RaterType,
} from "../../@types/raters.d";

import {
	deleteRater,
	remindRaters,
	resendRaterInvitation,
} from "../../services/apiService";
import { Link } from "react-router-dom";
import { ConfirmationModal } from "../../components/shared/confirmationModal";
import { RootState } from "../../store";
import { setLoading, setRaterReminderLastSentAt, setRaterType } from "../../store/User/UserSlice";
import { AddAssessmentRaters } from "./AddAssessmentRaters";
import { FormattedPhrase } from "../../context/FormattedPhrase";
import { useTranslationContext } from "../../context/TranslationContext";
import { useSearchParams } from "react-router-dom-v5-compat";
import { setReturnUrl } from "../../store/Navigation/NavSlice";
import moment from "moment";

// This is a shared method that is used to Remind specific rater types (in ReaterTypeCard) or all raters (in AssessmentRaters)
const sendRaterReminders = async (
	participantId: number,
	instrumentRaterResponse: InstrumentRaterResponse,
	raterType?: RaterType
) => {
	let emailRequests: Array<EmailRequest> = [];
	if (instrumentRaterResponse) {
		if (raterType) {
			// Find raters of this type
			instrumentRaterResponse.raterStatuses.forEach((status: RaterStatus) => {
				status.raters.forEach((rater: Rater) => {
					if (rater.raterTypeId === raterType.id) {
						emailRequests.push({
							participantId: participantId,
							raterId: rater.raterId,
						});
					}
				});
			});
		} else {
			// Find and add all raters regardless of type
			instrumentRaterResponse.raterStatuses.forEach((status: RaterStatus) => {
				status.raters.forEach((rater: Rater) => {
					emailRequests.push({
						participantId: participantId,
						raterId: rater.raterId,
					});
				});
			});
		}
	}

	let response = await remindRaters(emailRequests);
	return response;
};

type RaterTypeCardParams = {
	raterStatus: RaterStatus;
	onReload?: () => void;
};

export const RaterTypeCard: React.FC<RaterTypeCardParams> = ({
	raterStatus,
	onReload,
}) => {
	const dispatch = useDispatch();
	const [search] = useSearchParams();
	const { getFormattedPhrase } = useTranslationContext();
	const translatedLabels = {
		add: getFormattedPhrase("raterManagement.addRater.add"),
		rater: getFormattedPhrase("raterManagement.addRater.rater"),
		raters: getFormattedPhrase("raterManagement.addRater.raters"),
	};
	const theme = useTheme();

	const [showAlertMessage, setShowAlertMessage] = useState<string | null>(null);
	const [showRemindDialog, setShowRemindDialog] = useState<boolean>(false);
	const [showResendDialog, setShowResendDialog] = useState<boolean>(false);
	const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
	const [showRaters, setShowRaters] = useState<boolean>(true);
	const [raterDetail, setRaterDetail] = useState<Rater>();
	// const [reminderTimeout, setReminderTimeout] = useState(true);

	const { instrumentRaterResponse, participantId, raterType, raterReminderLastSentAt } = useSelector(
		(state: RootState) => state.user
	);

	const toggleShowRater = () => setShowRaters(!showRaters);

	const queryValues = () => {
		let retUrl = search.get("retUrl");
		localStorage.setItem("redirectUser","");
		if (retUrl) {
			dispatch(setReturnUrl(retUrl ?? ""));
			localStorage.setItem("returnToAp1", retUrl);
			localStorage.setItem("redirectUser", "");
			localStorage.setItem("redirectUser", "");
		}
		else {
			dispatch(setReturnUrl(""));
			localStorage.setItem("returnToAp1", "");
			localStorage.setItem("redirectUser", "");
		}
	}

	useEffect(() => {

	},[raterStatus.raterInvitedCount])

	useEffect(() => {
		queryValues();
	},[]);

	//temporarily disabling timeouts - 78254
	// useEffect(() => {
	// 	if (raterReminderLastSentAt) {
	// 		let timeoutCrossed = ((moment(Date.now()).diff(moment(raterReminderLastSentAt), "minutes")) as number > 10)
	// 		if (!timeoutCrossed !== reminderTimeout) {
	// 			setReminderTimeout(!timeoutCrossed);
	// 		}
	// 	} else {
	// 		setReminderTimeout(false);
	// 	}
	// },[reminderTimeout, raterReminderLastSentAt])

	const getRaterTitle = (id: number) => {
		switch(id) {
			case 2: return getFormattedPhrase("raterManagement.type.immediateBoss");
			break;
			case 3: return getFormattedPhrase("raterManagement.type.superior");
			break;
			case 4: return getFormattedPhrase("raterManagement.type.peer");
			break;
			case 5: return getFormattedPhrase("raterManagement.type.directReport");
			break;
			case 13: return getFormattedPhrase("raterManagement.type.boardMember");
			break;
		}
		return getFormattedPhrase("raterManagement.type.other");
	}
	
	return (
		<Card
			sx={{
				m: 3,
				boxShadow:
					"0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)",
			}}
		>
			{raterStatus.raterRequiredCount > raterStatus.raterInvitedCount && (
					<Stack sx={{alignItems: "center", flexDirection: "row", borderBottom: "1px solid #BA1A1A"}} py={3} px={5}>
						<InfoIcon sx={{color: theme.palette.error.main }}/>
						<Typography pl={5}>
							<FormattedPhrase id={'raterManagement.warning'}></FormattedPhrase>
						</Typography>
					</Stack>
				)}
			<CardContent sx={{ m: 2}}>
				
				<Typography variant="h5" fontWeight={900} pb={1}>
					{getRaterTitle(raterStatus.raterType.id)}
				</Typography>
				<Grid container mt={1}>
					{raterStatus.raterRequiredCount > 0 && (
						<>
							<Typography variant="body2" fontWeight={700} mt={1}>*{' '}
								<FormattedPhrase
									id={
										raterStatus.raterRequiredCount > 1
											? "raterManagement.card.requiredPlural"
											: "raterManagement.addRater.required"
									}
									values={{ count: raterStatus.raterRequiredCount }}
								/>
							</Typography>
							<Typography px={2}>|</Typography>
						</>
					)}
					{raterStatus.raterRecommendedCount > 0 && (
						<>
							<Typography variant="body2" mt={1} fontWeight={400}>
								<FormattedPhrase
									id={
										raterStatus.raterRecommendedCount > 1
											? "raterManagement.card.recommendedPlural"
											: "raterManagement.addRater.recommended"
									}
									values={{ count: raterStatus.raterRecommendedCount }}
								/>
							</Typography>
							<Typography px={2}>|</Typography>
						</>
					)}
					<Typography variant="body2" mt={1} fontWeight={400}>
						<FormattedPhrase
							id={
								"raterManagement.card.received"
							}
							values={{ count: raterStatus.raterSurveySubmittedCount }}
							isHtml={true}
						/>
					</Typography>
					<Typography px={2}>|</Typography>
					{raterStatus.raterInvitedCount > 0 ? (
							<Typography variant="body2" mt={1} fontWeight={400}>
								{getFormattedPhrase("raterManagement.card.assigned").replace("{{count}}", (raterStatus.raterInvitedCount+''))}
							</Typography>
						) : (
							<Fragment>
								<Typography>
									<FormattedPhrase
										id={
											"raterManagement.card.assigned"
										}
										values={{ count: raterStatus.raterInvitedCount }}
										isHtml={true}
									/>
								</Typography>
							</Fragment>
						)}
					{raterStatus.raters.length > 0 && (
						<Grid item xs={12} py={5} pl={1} pr={2}>
							{showRaters && (
								<Grid container border={"1px lightgray solid"} borderRadius={3}>
									{raterStatus.raters.map((rater: Rater, index: number) => (
										<Grid
											item
											key={index}
											xs={12}
											sx={{
												borderBottomWidth:
													raterStatus.raters.length - 1 > index ? 1 : 0,
												borderBottomColor: "lightgray",
												borderBottomStyle: "solid",
											}}
										>
											<Grid container alignItems={"center"} rowSpacing={0}>
												<Grid item xs={4} pl={3}>
													<Typography sx={{ wordWrap: "break-word", width: "100%", my: 2 }}>{rater.firstName} {rater.lastName}</Typography>
												</Grid>
												<Grid item xs={4} pl={5}>
													<Typography sx={{ wordWrap: "break-word", width: "100%", my: 2 }}>{rater.email}</Typography>
												</Grid>
												<Grid item xs={2} pl={5}>
													{rater.language.name}
												</Grid>
												<Grid item xs={2} sx={{ textAlign: "end" }}>
													<IconButton
														size="medium"
														aria-label="account of current user"
														aria-haspopup="true"
														aria-controls="menu-appbar"
														onClick={() => {
															//restrict reminder sending to once only every 10 minutes
															// if (raterReminderLastSentAt) {
																// if ((moment(Date.now()).diff(moment(raterReminderLastSentAt), "minutes")) as number > 10) { 
																	setRaterDetail(rater);
																	setShowResendDialog(true);
															// 		setReminderTimeout(false);
															// 	} else {
															// 		setReminderTimeout(true);
															// 	} 
															// } else {
															// 	setRaterDetail(rater);
															// 	setShowResendDialog(true);
															// }
														}}
													>
														{/* {reminderTimeout ? <CircularProgress size={15} sx={{mr: 2}}/> :  */}
															<EmailIcon
																sx={{ color: theme.palette.primary.main }}
															/>
														{/* } */}
													</IconButton>
													<IconButton
														size="medium"
														aria-label="account of current user"
														aria-haspopup="true"
														aria-controls="menu-appbar"
														onClick={() => {
															setRaterDetail(rater);
															setShowRemoveDialog(true);
														}}
													>
														<DeleteIcon
															sx={{ color: theme.palette.error.main }}
														/>
													</IconButton>
												</Grid>
											</Grid>
										</Grid>
									))}
								</Grid>
							)}
						</Grid>
					)}
				</Grid>

				<Grid container>
					<Grid item xs={12} sx={{ textAlign: "end" }}>
						{raterStatus.raterInvitedCount > 0 && (
							<Button
								variant="outlined"
								sx={{
									borderRadius: 10,
									mr: 2,
									px: 3,
								}}
								onClick={() => {
									//restrict reminder sending to once only every 10 minutes
									// if (raterReminderLastSentAt) {
									// 	if ((moment(Date.now()).diff(moment(raterReminderLastSentAt), "minutes")) as number > 10) { 
											dispatch(setRaterType(raterStatus.raterType));
											setShowRemindDialog(true)
									// 		setReminderTimeout(false);
									// 	} else {
									// 		setReminderTimeout(true);
									// 	} 
									// } else {
									// 	dispatch(setRaterType(raterStatus.raterType));
									// 	setShowRemindDialog(true);
									// }
								}}
							>
								<Typography color={theme.palette.secondary.main} fontSize={14} fontWeight={400}>
									<FormattedPhrase id="raterManagement.card.buttons.remind" />
								</Typography>
								{/* {reminderTimeout ? <CircularProgress size={15} sx={{ ml: 2, color: theme.palette.secondary.main }}/> :  */}
								<NotificationsIcon sx={{ ml: 2, color: theme.palette.secondary.main }}/>
								{/* } */}
							</Button>
						)}
						<AddAssessmentRaters
							id={raterStatus.instrument.instrumentId}
							pid={raterStatus.participantId}
							raterType={raterStatus.raterType}
							raterStatus={raterStatus}
							onReload={() => {
								if (onReload) onReload();
							}}
						/>
					</Grid>
				</Grid>

				<ConfirmationModal
					isOpen={showAlertMessage !== null}
					onYes={() => {
						setShowAlertMessage(null);
					}}
					title={""}
					promptText={showAlertMessage as string}
					yesText={getFormattedPhrase("dialog.ok")}
				/>
				<ConfirmationModal
					isOpen={showRemindDialog}
					title={getFormattedPhrase("raterManagement.remindRaters.title")}
					promptText={getFormattedPhrase(
						"raterManagement.remindRaters.confirmation"
					)}
					yesText={getFormattedPhrase("dialog.yes")}
					defaultButton={getFormattedPhrase("dialog.yes")}
					noText={getFormattedPhrase("dialog.no")}
					onYes={async () => {
						try {
							let response = await sendRaterReminders(
								participantId,
								instrumentRaterResponse,
								raterType
							);
							if (response.result === "Success") {
								let msg = getFormattedPhrase(
									"raterManagement.remindRatersSuccess.confirmation"
								);
								setShowAlertMessage(msg);
								dispatch(setRaterReminderLastSentAt(moment(Date.now()).toString()));
							}
						} catch(e) {
							console.log(e);
						} finally {
							setShowRemindDialog(false);
						}
					}}
					onNo={() => setShowRemindDialog(false)}
				/>
				<ConfirmationModal
					isOpen={showResendDialog}
					title={getFormattedPhrase("raterManagement.resendInvitation.title")}
					promptText={getFormattedPhrase(
						"raterManagement.resendInvitation.confirmation"
					).replace(
						"{{raterName}}",
						raterDetail?.firstName + " " + raterDetail?.lastName
					)}
					yesText={getFormattedPhrase("dialog.yes")}
					defaultButton={getFormattedPhrase("dialog.yes")}
					noText={getFormattedPhrase("dialog.no")}
					onYes={async () => {
						let response = await resendRaterInvitation({
							participantId: participantId,
							raterId: raterDetail?.raterId as number,
						});
						if (response.result === "Success") {
							let msg = getFormattedPhrase(
								"raterManagement.resendInvitationSuccess.confirmation"
							);
							setShowAlertMessage(msg);
							dispatch(setRaterReminderLastSentAt(moment(Date.now()).toString()));
						}
						setShowRemindDialog(false);
						setShowResendDialog(false);
					}}
					onNo={() => setShowResendDialog(false)}
				/>
				<ConfirmationModal
					isOpen={showRemoveDialog}
					title={getFormattedPhrase("raterManagement.deleteRater.title")}
					promptBody={
						<FormattedPhrase
							id="raterManagement.deleteRater.confirmation"
							isHtml={true}
							values={{
								name: raterDetail?.firstName + " " + raterDetail?.lastName,
								contactNumbers:
									"<p>CCL Americas: support.ccl.org<br/>CCL EMEA (Europe, Middle East,Africa): +32 (0) 2 679 09 10<br/>CCL Asia Pacific: +65 6854 6000<br/></p>",
							}}
						/>
					}
					yesText={getFormattedPhrase("dialog.yes")}
					noText={getFormattedPhrase("dialog.no")}
					onYes={async () => {
						dispatch(setLoading(true));
						await deleteRater(raterDetail?.raterId as number);
						setShowRemoveDialog(false);
						if (onReload) onReload();
						dispatch(setLoading(false));
					}}
					dangerButton={getFormattedPhrase("dialog.yes")}
					onNo={() => setShowRemoveDialog(false)}
				/>
			</CardContent>
		</Card>
	);
};
